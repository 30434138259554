.Event {
  padding-top: 50px;
  display: block;
  text-align: center;
}

.ui.centered.grid {
  row-gap: 15px;
}

#cardRow {
  column-gap: 50px;
  padding: 0%;
}

#cardRow > .ui.card {
  width: 425px;
  border-radius: 8px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  margin: 0px 0px 25px 0px;
  text-align: left;
}

#cardRow > .ui.card > .content {
  padding: 40px;
}

#cardRow > .ui.card > .content > .header {
  font-size: 25px;
  font-weight: bolder;
  font-family: BoldFont;
}

#cardRow > .ui.card > .content > .meta {
  font-size: 15px;
  font-family: BoldFont;
}

#cardRow > .ui.card > .content > .description {
  font-size: 15px;
  font-family: ParagraphFont;
}
