.Sponsor {
  display: block;
  text-align: center;
}

.row > a > img {
  max-width: 100%;
  align-self: center;
  transition: 0.2s;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.row a {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.row > a > img:hover {
  transform: scale(1.12);
}
