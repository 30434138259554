.Front {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 75vh;
  margin-bottom: 33vh;
  z-index: 1;
  overflow: hidden;
}

.Front::before {
  content: "";
  position: absolute;
  top: -5px;
  background-image: linear-gradient(
      to right,
      rgba(1, 1, 1, 0.75),
      rgba(1, 1, 1, 0.75)
    ),
    url("../../images/coverPhoto.png");
  background-repeat: no-repeat, no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  height: 100vh;
  width: 100vw;
}

.Hero {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.TextLogo {
  align-self: center;
  height: auto;
  width: 35vw;
  padding-bottom: 0px;
  padding-top: 65px;
}

.HeroText {
  width: 100%;
  text-align: center;
}

h1 {
  color: white;
}

.btn {
  background-color: #ffffff !important;
  color: black;
}

.logobutton button {
  font-family: BoldFont;
  font-size: 15px;
  font-weight: 100;
  margin: 20px;
  border: none;
  border-radius: 8px;
  width: 175px;
  height: 55px;
  margin-top: 70px;
  transition: 0.3s ease-in-out;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.411)) top/100% 800%;
}
.logobutton button:hover {
  background-position: bottom;
  cursor: pointer;
}

@media only screen and (max-width: 2560px) {
  h1 {
    font-size: 1.4vw;
  }
}

@media only screen and (max-width: 1440px) {
  h1 {
    font-size: 1.4vw;
  }
}

@media only screen and (max-width: 1080px) {
  .TextLogo {
    width: 50%;
  }
  h1 {
    font-size: 2vw;
  }
}

@media only screen and (max-width: 850px) {
  h1 {
    font-size: 3vw;
  }
  .HeroText {
    width: 40%;
    text-align: center;
  }
}

@media only screen and (max-width: 705px) {
  .TextLogo {
    align-self: center;
    height: auto;
    width: 70%;
  }

  h1 {
    font-size: 3.8vw;
  }
  .HeroText {
    width: 50%;
    text-align: center;
  }
}
