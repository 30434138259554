.Footer {
  justify-content: center;
}

.FooterBody {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}

.FooterLogo {
  display: block;
}

.FooterLogo img {
  width: 175px;
  height: auto;
  margin: 0px auto;
  padding-top: 50px;
}

.LogoText {
  display: block;
  font-size: 10px;
  margin-bottom: 120px;
}

@media only screen and (max-width: 10000px) {
  .MediaLinks {
    display: block;
    flex-direction: row;
    float: left;
    margin: auto;
  }

  .MediaLinks a {
    margin: 15px;
  }

  .FooterLinks {
    float: left;
    margin: auto;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 15px;
  }

  .FooterLinks a {
    font-size: 12px;
    color: #0d0d0d;
    font-weight: 500;
  }

  .FooterLinks a:hover {
    text-decoration: underline;
  }

  .Signature {
    float: left;
    margin: auto;
  }

  .Signature p {
    font-size: 12px;
  }

  .FooterContainer {
    display: grid;
    width: 75%;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 1520px) {
  .FooterContainer {
    display: grid;
    width: 75%;
    grid-template-columns: auto;
    margin-bottom: 25px;
    gap: 50px;
  }

  .FooterLinks {
    float: left;
    margin: auto;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 15px;
    grid-row-gap: 30px;
    justify-items: center;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .MediaLinks {
    display: block;
    flex-direction: row;
    float: left;
    margin: auto;
  }

  .MediaLinks a {
    margin: 7px;
  }
}
