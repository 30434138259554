@media only screen and (max-width: 10000px) {
  .Navbar {
    z-index: 10;
    left: 0%; /* HAS TO MATCH APP margin-left - 2*/
    right: 0%; /* HAS TO MATCH APP margin-right - 2*/
    top: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.525);
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    height: 90px;
    align-items: center;
    width: 100%;
    transition: top 0.3s;
  }

  .Navbar img {
    margin-top: 26px;
    margin-left: 300px;
    height: auto;
    width: 42px;
  }

  .Navbar img:hover {
    cursor: pointer;
  }

  .PageTitle {
    align-self: flex-start;
  }

  .NavLinks {
    display: flex;
    flex-direction: row;
    margin-right: 300px;
    align-items: center;
  }

  .PageLinks {
    margin-right: auto;
    height: auto;
    display: flex;
  }

  .PageLinks a {
    color: #ffffff;
    margin-left: 30px;
    margin-top: 5px;
    text-decoration: none;
    padding: 6px;
    font-weight: 700;
    font-size: 15px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .NavLinks box-icon {
    margin: 15px;
  }

  .MobileMenu {
    display: none;
  }

  .PageLinks a:hover {
    transition: 0.3s ease-out;
    border-radius: 5px;
    background: #c7c7c764;
    transition: 0.3s ease-out;
  }
}

@media only screen and (max-width: 1800px) {
  .Navbar {
    left: 0;
    right: 0;
  }

  .Navbar img {
    margin-left: 150px;
  }

  .NavLinks {
    margin-right: 150px;
  }
}

@media only screen and (max-width: 1250px) {
  .PageLinks {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .Navbar {
    left: 0;
    right: 0;
  }

  .Navbar img {
    margin-left: 30px;
  }

  .NavLinks {
    margin-right: 30px;
  }

  .PageLinks {
    display: none;
  }
}

@media only screen and (max-width: 950px) {
  .Navbar {
    left: 0;
    right: 0;
  }

  .PageLinks {
    display: none;
  }

  .NavLinks {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    align-items: center;
  }
}

@media only screen and (max-width: 510px) {
  .NavLinks {
    display: none;
  }
}

@media only screen and (max-width: 321px) {
  .MediaLinks a {
    margin: 1px;
  }
}
