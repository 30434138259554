@import url("https://fonts.googleapis.com/css?family=Muli");
@import url("https://fonts.googleapis.com/css?family=Poppins");

/*
UOTTHACC 2018 LOGO HEX COLORS:
BLUE BORDER #3366cc
BLUE TEXT #084259
RED TEXT #8b181d

uOttaHack 2019 Hex Colors:
Teal #47B9A9
Blue #086788
Orange #FCA311


*/

@font-face {
  font-family: HeaderFont;
  src: url(../../fonts/Inter/Inter-Black.ttf);
}

@font-face {
  font-family: BoldFont;
  src: url(../../fonts/Inter/Inter-Bold.ttf);
}

@font-face {
  font-family: ParagraphFont;
  src: url(../../fonts/Inter/Inter-Regular.ttf);
}

@font-face {
  font-family: SemiFont;
  src: url(../../fonts/Inter/Inter-SemiBold.ttf);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-family: ParagraphFont;
}

h1 {
  font-family: BoldFont;
  font-size: 2em;
}

h2 {
  color: #0d0d0d;
  font-size: 3.25em;
  font-family: BoldFont;
  margin-bottom: 0;
}

h3 {
  color: #0d0d0d;
  font-size: 1.5em;
  font-family: BoldFont;
}

h5 {
  color: gray;
  font-family: SemiFont;
}

p {
  font-size: 1.5em;
  font-family: ParagraphFont;
}

hr {
  width: 75%;
  margin: auto;
  background: gainsboro;
  color: gainsboro;
  border: none;
  height: 1px;
}
