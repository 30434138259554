@media screen and (max-width: 10000px) {
  .Past {
    width: 100%;
    max-width: 120%;
  }
  .Row {
    display: flex;
    flex-direction: row;
  }

  .logo {
    width: 150px;
    height: 150px;
    align-self: center;
  }
  .logo-2019 {
    width: 150px;
    height: 150px;
    align-self: center;
  }
  .logobutton button {
    font-family: BoldFont;
    font-size: 15px;
    font-weight: 100;
    margin: 20px;
    border: none;
    border-radius: 8px;
    width: 175px;
    height: 55px;
    margin-top: 70px;
    transition: 0.3s ease-in-out;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.411)) top/100% 800%;
  }
  .logobutton button:hover {
    background-position: bottom;
    cursor: pointer;
  }
  .btn1 {
    background-color: #92051a !important;
    width: 50%;
    color: white;
  }
  .btn2 {
    background-color: #20c9a2 !important;
    color: white;
  }
  .btn3 {
    background-color: #404ca3 !important;
    color: white;
  }
  .btn4 {
    background-color: #373737 !important;
    color: #ffffff;
  }
  .btn5 {
    background-color: #41206a !important;
    color: #ffffff;
  }

  .Hackathon {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px; /* Optional: Add gap between grid items */
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 50px;
  }
  .circle {
    height: 12px;
    width: 12px;
    background-color: #595959;
    margin: auto;
    margin-top: 21px;
    border-radius: 50%;
  }
  .line {
    height: 60%;
    background-color: #595959;
    width: 2px;
    margin: auto;
  }
  .Timeline {
    height: 2px;
    background-color: #595959;
    width: 100%;
    position: absolute;
    top: 360px;
    margin-left: -5%;
  }
}

@media screen and (max-width: 991px) {
  .logobutton button {
    margin-top: 50px;
  }
  .logo {
    width: 200px;
    height: 200px;
    align-self: center;
  }
  .logo-2019 {
    width: 188px;
    height: 200px;
    align-self: center;
  }
  .Hackathon {
    margin-left: 30px;
    margin-right: 30px;
  }
  .Timeline {
    display: none;
  }
  .line {
    display: none;
  }
  .circle {
    display: none;
  }
}

@media screen and (max-width: 511px) {
  .Hackathon {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
