.Organizer {
  align-content: center;
  justify-content: center;
  text-align: center;
}

.menu {
  align-content: left;
  justify-content: left;
  text-align: left;
}

.Organizer-Group {
  margin-bottom: 50px;
}

#body .btn-org {
  margin: 20px;
}

.Organizer-Group {
  margin-bottom: 50px;
}

#cards > .ui.card {
  width: 275px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  margin: 15px;
}

#cards > .ui.card > .content > .header {
  font-family: SemiFont;
}

#cards > .ui.card > .content > .meta {
  font-family: ParagraphFont;
  color: rgb(150, 150, 150);
}

h3 {
  margin-top: 30px;
}

img {
  margin-top: 30px;
}
